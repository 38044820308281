
import { mapMutations, mapState } from 'vuex'
import { BIconX } from 'bootstrap-vue'
// import ModalProductPreview from '~/components/modal/product_preview.vue'
export default {
  components: {
    BIconX
    // ModalProductPreview
  },
  async asyncData ({ params, $axios, $replaceSEO }) {
    let seo
    if (params.category) {
      seo = await $axios.get('sitemaps/product_groups_category')
    } else {
      seo = await $axios.get('sitemaps/product_groups')
    }
    let productGroupCategories = []
    let sampleProducts = []

    productGroupCategories = await $axios.get('product-group-categories')
    productGroupCategories = productGroupCategories.data[0]
    const tmpSampleProductCategories = {}
    for (const k in productGroupCategories) {
      tmpSampleProductCategories[productGroupCategories[k].id] = productGroupCategories[k]
    }

    sampleProducts = await $axios.get('product_group_category/' + params.category + '/product_group')
    sampleProducts = sampleProducts.data.data

    const item = productGroupCategories.filter(x => x.id === parseInt(params.category))
    const catName = (parseInt(params.category) === 0) ? 'ทั้งหมด' : item[0].name
    return {
      productGroupCategories,
      productGroupCategoryCount: productGroupCategories.length,
      sampleProducts,
      seletedCategoryId: params.category,
      name: catName,
      seo: $replaceSEO(seo.data.data, null, params.category, null, catName, null),
      sampleProductCategoriesKeys: tmpSampleProductCategories
    }
  },
  data () {
    return {
      productGroupCategories: [],
      categoryMenuHeight: '72px',
      sampleProducts: [],
      seletedCategoryId: 0,
      waypointLoaded: [],
      maxImpression: 6,
      slide: 0,
      sliding: null,
      loading: false,
      modalProductDetial: {
        name: '',
        description: '',
        imageSlide: [],
        relatedProducts: []
      },
      headTitle: 'รวมงาน ออกแบบ บรรจุภัณฑ์ กล่องกระดาษ สติกเกอร์ ทำกล่อง สวยๆ',
      headDes: 'ให้บริการออกแบบ และผลิตบรรจุภัณฑ์ โดยโรงงานกล่องกระดาษ กล่องไปรษณีย์ กล่องสวยๆ กล่องครีม สติกเกอร์ติดกล่อง ครบจบในที่เดียว สะดวกเช็คราคา สั่งซื้อ ออนไลน์24hr.',
      headKey: 'ผลิตกล่อง,กล่องกระดาษ,กล่องไปรษณีย์,กล่องลูกฟูก,กล่องสวยๆ,กล่องครีม,สติกเกอร์,สติกเกอร์ติดกล่องอาหาร,ออกแบบบรรจุภัณฑ์,โรงงานผลิตกล่อง,บรรจุภัณฑ์,กล่องส่งพัสดุ,กล่องอาหาร,หลอดครีม,ขวดพลาสติก,ถุงพลาสติก,พลาสติก,กล่องสวยๆ,ส่งเดลิเวอรี่,การส่งพัสดุ,การขายออนไลน์'
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    this.waypointLoaded = []
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'category',
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    showCategoryMenu () {
      this.categoryMenuHeight = this.categoryMenuHeight === '100%' ? '72px' : '100%'
    },
    productClicked (pattern, indexPT) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productClicked',
        ecommerce: {
          click: {
            actionField: { list: 'บรรจุภัณฑ์แยกตามประเภทสินค้า' },
            products: [{
              id: `LOCOPACK_SAMPLE_${pattern.sample_product_category_id}` + pattern.id.toString().padStart(9, '0'),
              name: pattern.name,
              price: pattern.price_per_pcs,
              category: this.sampleProductCategoriesKeys[pattern.sample_product_category_id].name,
              brand: 'Locopack',
              position: indexPT + 1,
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            }]
          }
        }
      })
    },
    onWaypoint ({ going, direction, el }) {
      // console.log('waypoint going in!', going === this.$waypointMap.GOING_IN, typeof direction === 'undefined', el.getAttribute('data-pattern-index'))
      const inIdx = parseInt(el.getAttribute('data-pattern-index'))
      if ((going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP) ||
      (going === this.$waypointMap.GOING_IN && typeof direction === 'undefined' && (inIdx === 0 || inIdx === 6))) {
        const impressions = []
        let pos = 0
        for (let k = inIdx; pos < this.maxImpression; k++) {
          const item = this.sampleProducts[k]
          // console.log(this.sampleProductCategories[item.sample_product_category_id].name)
          // console.log(k, item)
          if (k in this.sampleProducts && typeof this.waypointLoaded[item.id] === 'undefined' && this.waypointLoaded[item.id] !== true) {
            impressions.push({
              id: `LOCOPACK_SAMPLE_${item.sample_product_category_id}` + item.id.toString().padStart(9, '0'),
              name: item.name,
              price: ((item.price_per_pcs !== null) ? item.price_per_pcs.toFixed(2) : 0),
              category: this.sampleProductCategoriesKeys[item.sample_product_category_id].name,
              brand: 'Locopack',
              list: 'บรรจุภัณฑ์ตามชนิดสินค้า',
              position: (k + 1),
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            })
            this.waypointLoaded[item.id] = true
          }
          pos++
        }
        // console.log(impressions)
        if (impressions.length > 0) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'impression',
            ecommerce: {
              impressions
            }
          })
        }
      }
    },
    change_category (e, productCategoryId) {
      e.preventDefault()
      this.seletedCategoryId = productCategoryId
      this.getSampleProductByCategory()
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    linkToProduct (pkgType, mappingInputId, productId) {
      if (pkgType !== 4) {
        return { name: 'customize-product-design-id', params: { design: true, id: mappingInputId }, query: { productId } }
      } else {
        return { name: 'products-detail-id', params: { id: mappingInputId }, query: { productId } }
      }
    },
    async getSampleProductByCategory () {
      // set state
      this.loading = true

      const response = await this.$axios.get('sample_product_category/' + this.seletedCategoryId + '/sample_products')
      this.sampleProducts = response.data.data

      // set state
      this.loading = false
    },
    async setModalDetail (productDetail, indexPT) {
      // this.$ga.event('PurchaseFlow', 'Click', 'SampleProductSelect-' + productDetail.id)
      // this.productClicked(productDetail, indexPT)
      // this.modalProductDetial = {
      //   name: productDetail.name,
      //   description: productDetail.description,
      //   imageSlide: productDetail.img,
      //   relatedProducts: productDetail.products,
      //   videoable: productDetail.videoable
      // }
      await this.$axios.get(`sample-product-tracking/${productDetail.id}`)
      this.$router.push(this.localeLocation({ name: 'sample-product-id', params: { id: productDetail.id } }))
    },
    setPreviewProduct (productDetail) {
      // this.$ga.event('PurchaseFlow', 'Click', 'SampleProductSelectProduct-' + productDetail.mapping_input.id)
      // @TODO image path conflic in product_preview component
      this.$refs.productPreviewModal.pathMode = 'FULL_PATH'
      this.$refs.productPreviewModal.ModalProduct = {
        id: productDetail.mapping_input.id,
        name: productDetail.mapping_input.name_th,
        description: productDetail.mapping_input.description,
        pattern_img: productDetail.mapping_input.pattern_img,
        per_pcs: productDetail.price_per_pcs
      }
    }
  },
  head () {
    return {
      title: this.seo.title,
      meta: [
        { hid: 'description', name: 'description', content: this.seo.description },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ]
    }
  }
}
