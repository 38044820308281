
/* eslint-disable */
import emailModal from '~/components/modal/email.vue'
import NeedHelpButton from '~/components/UI/need-help-btn.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  components: {
    emailModal, NeedHelpButton
  },
  async asyncData ({ params, $axios, $cookies, $replaceSEO, store }) {
    // const seo = await $axios.get('sitemaps/products_customize')
    let id = params.id
    /* let checkoutParams = store.state.checkoutStandardProduct
    console.log(checkoutParams)
    let amount = checkoutParams.amount
    let zipcode = checkoutParams.zipcode
    let design = checkoutParams.design
    let promo_code = checkoutParams.promo_code
    let discount_type = checkoutParams.discount_type
    let reward_id = checkoutParams.reward_id
    let is_redeem = checkoutParams.is_redeem
    let uuid = checkoutParams.uuid
    const result = await $axios.$get(`/standard-products/${id}/checkout`, { params: checkoutParams }) */
		const orderKeyRef = `stdProduct${id}ToCreateOrder`
		return {
			id,
			/* amount,
			zipcode,
			uuid,
			design,
			promo_code,
			discount_type,
			reward_id,
			is_redeem,
      result, */
			orderKeyRef,
			// seo: $replaceSEO(seo.data.data, rigid.name, id, null, null, null)
		}
	},
	data() {
		return {
      amount: null,
      zipcode: null,
      uuid: null,
      design: null,
      promo_code: null,
      discount_type: null,
      reward_id: null,
      is_redeem: null,
      result: {}
		}
	},
	computed: {
		...mapState(["userData", "checkoutStandardProduct"]),
		...mapGetters(["isAuthenticated"])
	},
	async mounted() {
    this.showLoading()
    try {
      let checkoutData = JSON.parse(localStorage.getItem(`checkout-std-product-${this.id}`))
      const result = await this.$axios.$get(`/standard-products/${this.id}/checkout`, { params: checkoutData })
      if (result.status == false) {
        this.setAlertWithLine({
          show: true,
          message: result.msg,
          header: "แจ้งเตือน"
        })
      }
      this.amount = checkoutData.amount
      this.zipcode = checkoutData.zipcode
      this.uuid = checkoutData.uuid
      this.design = checkoutData.design
      this.promo_code = checkoutData.promo_code
      this.discount_type = checkoutData.discount_type
      this.reward_id = checkoutData.reward_id
      this.is_redeem = checkoutData.is_redeem
      this.result = result.data
    } catch (error) {
      this.setAlertWithLine({
        show: true,
        message: "พบปัญหาในการตรวจสอบข้อมูล กรุณาติดต่อทีมงาน Locopack",
        header: "แจ้งเตือน",
      })
    }
    this.hideLoading()
		/* window.dataLayer = window.dataLayer || []
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: "pageInfo",
			page: {
				type: this.$route.name,
				path: `${this.$route.path}`,
				title: this.seo.title,
			},
			user: {
				customerID: this.userData ? this.userData.uid : "",
				customerEmail: this.userData ? this.userData.email : ""
			},
		});
		this.showLoading();
		if (this.getOrder2LocalStorage(this.orderKeyRef)) {
			const orderLocalStorage = this.getOrder2LocalStorage(this.orderKeyRef);

			this.addOnSticker = orderLocalStorage.addOnSticker;
			this.addOnFolding = orderLocalStorage.addOnFolding;
			this.id = orderLocalStorage.id;
			this.amount = orderLocalStorage.amount;
			this.zipcode = orderLocalStorage.zipcode;
			this.customizeData = orderLocalStorage.customizeData;
			this.rigid = orderLocalStorage.rigid;
			this.sticker = orderLocalStorage.sticker;
			this.folding = orderLocalStorage.folding;
			this.design = orderLocalStorage.design;
			this.uuid = orderLocalStorage.uuid;
		}
		this.storeOrder2LocalStorage(null);
		this.hideLoading(); */
	},
	methods: {
		...mapMutations(["showLoading", "hideLoading", "setAlert", "setAlertWithLine"]),
		goToCheckout(flag) {
			this.insertOrder('checkOut')
		},
    productDetail () {
      var size = `${this.result.standard_product.w / 10} x ${this.result.standard_product.l / 10}`
      size += this.result.standard_product.h ? ` x ${this.result.standard_product.h / 10}` : ''
      return `ขนาด ${size} รหัสสินค้า ${this.result.standard_product.locopack_code}`
    },
    totalPrice() {
      let price = parseFloat(this.result.total)
      if (this.design === 'design') {
        price += parseFloat(this.result.standard_product.design_price)
      }
      return this.$formatPriceFloat(price)
    },
    async createOrder () {
      if (!this.isAuthenticated) {
        localStorage.setItem('back_url', this.$route.fullPath)
        this.$router.push(this.localeLocation({ name: "auth-login-via-phone" }))
      } else {
        this.showLoading()
        const result = await this.$axios.$post(`/standard-products/create-order`, {
          id: this.id,
          amount: this.amount,
          zipcode: this.zipcode,
          uuid: this.uuid,
          design: this.design,
          promo_code: this.promo_code,
          discount_type: this.discount_type,
          reward_id: this.reward_id,
          is_redeem: this.is_redeem
        })
        if (result.error === 'verifyEmail') {
          this.$refs.emailModal.show = true
          this.hideLoading()
          return false
        } else if (result.status === 0 || result.status === false) {
          this.hideLoading()
          this.setAlertWithLine({
            show: true,
            message: result.msg,
            header: 'แจ้งเตือน'
          })
          return false
        }
        if (result.status === true) {
          /* if (process.client) {
            localStorage.removeItem(`checkout-std-product-${this.id}`)
            localStorage.removeItem(this.orderKeyRef)
          } */
          if (this.design === 'design') {
            const parameters = {
              // order_code: result.data.order_code,
              id: result.data.order_id
            }
            this.$router.push(this.localeLocation({
              name: 'brief-design-id',
              params: parameters
            }))
          } else {
            this.$router.push(this.localeLocation({
              name: "checkout-id",
              params: { id: result.data.order_id },
            }));
          }
        }
      }
    },
		/* prepareData(type, item) {
			const postPatterns = [];
			if (type === "sticker") {
				postPatterns.push({
					color: 1,
					enamel_id: null,
					packaging_type_id: item.packaging_type_id,
					paper_type_id: item.selected.paper,
					pattern_id: 0,
					special_technic_id: [],
					option_detail: this.stickerOptionDetail(),
					detail: this.stickerOptionDetail(),
				});
			} else {
				postPatterns.push({
					color: 1,
					enamel_id: item.selected.enamel,
					packaging_type_id: item.packaging_type_id,
					paper_gsm: item.selected.gsm,
					paper_type_id: item.selected.paper,
					pattern_id: 0,
					special_technic_id: item.selected.special,
					option_detail: this.foldingOptionDetail(),
					detail: this.foldingOptionDetail(),
				});
			}

			// console.log(postPatterns)
			const data = {
				type: 0,
				mapping_pattern_id: item.mapping_input_id,
				patterns: postPatterns,
				amount: item.amount,
				w: item.w,
				l: item.l,
				h: item.h,
				color: 1,
				zipcode: this.zipcode,
				promo_code : this.promo_code,
				reward_id : this.reward_id,
				is_redeem : this.is_redeem,
				mapping_inputs: {
					is_combination: 0,
					mapping_input_type_id: item.mapping_input_id,
					packaging_type_id: item.packaging_type_id,
				},
				is_brief_design: 0,
				detail: "",
			};
			return data;
		},
		async calculateFactory(type, item) {
			if (parseInt(this.rigid.amount) < 100) {
				this.setAlertWithLine({
					show: true,
					message: "จำนวนขวดขั้นต่ำ 100 ชิ้น",
					header: "แจ้งเตือน",
				});
				return;
			}
			if (this.addOnSticker === "1" && parseInt(this.sticker.amount) < 100) {
				this.setAlertWithLine({
					show: true,
					message: "จำนวนสติกเกอร์ขั้นต่ำ 100 ชิ้น",
					header: "แจ้งเตือน",
				});
				return;
			}
			if (this.addOnFolding === "1" && parseInt(this.folding.amount) < 100) {
				this.setAlertWithLine({
					show: true,
					message: "จำนวนกล่องขั้นต่ำ 100 ชิ้น",
					header: "แจ้งเตือน",
				});
				return;
			}
			const postData = this.prepareData(type, item);
			const result = await this.$axios.$post("products", postData);
        item.calculated = result.data;
        item.naming = result.naming;
        item.factorySelected = result.data[0];
    },
    async calculateAmount() {
      const calculateData = await this.$axios.$get(
        `/rigids/${this.id}/calculate`,
        {
          params: {
            amount: this.rigid.amount,
            zipcode: this.rigid.zipcode,
          },
        }
      );

      this.rigid.per_pcs = calculateData.per_pcs;
      this.rigid.sum = calculateData.sum;
      this.rigid.delivery_price = calculateData.delivery_price;
      this.rigid.total = calculateData.total;
    },
    totalPrice() {
      let price = parseFloat(this.result.total)
      if (this.design==='design') {
        price += parseFloat(this.result.standard_product.design_price)
      }
      return this.$formatPriceFloat(price)
    },
    totalSticker() {
      let price = parseFloat(this.sticker.factorySelected.total);

      if (this.sticker.design_check === "1") {
        price += parseFloat(this.sticker.design_price);
      }
      return this.$formatPriceFloat(price);
    },
    totalFolding() {
      let price = parseFloat(this.folding.factorySelected.total);
      if (this.folding.design_check === "1") {
        price += parseFloat(this.folding.design_price);
      }

      return this.$formatPriceFloat(price);
    },
    showNameId(data, id) {
      const item = data.filter((x) => x.id === id);
      return item[0].name;
    },
    showNameValue(data, value) {
      const item = data.filter((x) => x.value === value);
      return item[0].name;
    },
    editRigid() {
      this.rigid.edit.amount = this.rigid.amount;
      this.rigid.edit.zipcode = this.rigid.zipcode;
      this.rigid.edit.status = !this.rigid.edit.status;
    },
    async saveRigid() {
      if (parseInt(this.rigid.edit.amount) < 100) {
        this.rigid.edit.amount = 100;
        this.setAlertWithLine({
          show: true,
          message: "จำนวนขวดขั้นต่ำ 100 ชิ้น",
          header: "แจ้งเตือน",
        });
        return;
      }
      this.showLoading();

      this.rigid.amount = this.rigid.edit.amount;
      this.rigid.zipcode = this.rigid.edit.zipcode;
      this.sticker.amount = this.rigid.edit.amount;
      this.folding.amount = this.rigid.edit.amount;

      await this.calculateAmount("rigid", this.rigid.edit.amount);
      await this.calculateFactory("sticker", this.sticker);
      await this.calculateFactory("folding", this.folding);

      this.hideLoading();
      this.rigid.edit.status = false;
    },
    editSticker() {
      this.sticker.edit.amount = this.sticker.amount;
      this.sticker.edit.color = this.sticker.selected.color;
      this.sticker.edit.screen = this.sticker.selected.screen;
      this.sticker.edit.paper = this.sticker.selected.paper;

      this.sticker.edit.status = !this.sticker.edit.status;
    },
    async saveSticker() {
      if (
        this.addOnSticker === "1" &&
        parseInt(this.sticker.edit.amount) < 100
      ) {
        this.sticker.edit.amount = 100;
        this.setAlertWithLine({
          show: true,
          message: "จำนวนสติกเกอร์ขั้นต่ำ 100 ชิ้น",
          header: "แจ้งเตือน",
        });
        return;
      }
      this.showLoading();
      const item = await this.sticker.screens.filter(
        (x) => x.name === this.sticker.edit.screen
      );
      this.sticker.w = parseFloat(item[0].w);
      this.sticker.l = parseFloat(item[0].l);
      this.sticker.amount = this.sticker.edit.amount;
      this.sticker.selected.color = this.sticker.edit.color;
      this.sticker.selected.screen = this.sticker.edit.screen;
      this.sticker.selected.paper = this.sticker.edit.paper;
      await this.calculateFactory("sticker", this.sticker);
      this.hideLoading();
      this.sticker.edit.status = false;
    },
    editFolding() {
      this.folding.edit.amount = this.folding.amount;
      this.folding.edit.color = this.folding.selected.color;
      this.folding.edit.pattern = this.folding.name;
      this.folding.edit.paper = this.folding.selected.paper;
      this.folding.edit.gsm = this.folding.selected.gsm;
      this.folding.edit.enamel = this.folding.selected.enamel;
      this.folding.edit.special = this.folding.selected.special;
      this.folding.edit.status = !this.folding.edit.status;
    },
    async saveFolding() {
      if (
        this.addOnFolding === "1" &&
        parseInt(this.folding.edit.amount) < 100
      ) {
        this.folding.edit.amount = 100;
        this.setAlertWithLine({
          show: true,
          message: "จำนวนกล่องขั้นต่ำ 100 ชิ้น",
          header: "แจ้งเตือน",
        });
        return;
      }
      this.showLoading();
      this.folding.amount = this.folding.edit.amount;
      this.folding.color = this.folding.edit.color;
      this.folding.selected.paper = this.folding.edit.paper;
      this.folding.selected.gsm = this.folding.edit.gsm;
      this.folding.selected.enamel = this.folding.edit.enamel;
      this.folding.selected.special = this.folding.edit.special;
      await this.calculateFactory("folding", this.folding);
      this.hideLoading();
      this.folding.edit.status = false;
    },
    factoryStickerShow(factory, img) {
      this.$refs.factorySticker.show = true;
      this.$refs.factoryFolding.packaging_type_id = 2;
      this.$refs.factorySticker.factoryList = factory;
      this.$refs.factorySticker.factorySelected =
        this.sticker.factorySelected.factory_alphabet;
      this.$refs.factorySticker.img = img;
    },
    factoryFoldingShow(factory, img) {
      this.$refs.factoryFolding.show = true;
      this.$refs.factoryFolding.packaging_type_id = 1;
      this.$refs.factoryFolding.factoryList = factory;
      this.$refs.factoryFolding.factorySelected =
        this.folding.factorySelected.factory_alphabet;
      this.$refs.factoryFolding.img = img;
    },
    storeOrder2LocalStorage(orderData) {
      if (process.client) {
        localStorage.setItem(this.orderKeyRef, JSON.stringify(orderData));
      }
    },
    getOrder2LocalStorage(orderData) {
      if (process.client) {
        return JSON.parse(localStorage.getItem(this.orderKeyRef));
      }
    },
    async insertOrder(goTo) {
      const routePath = this.$route.fullPath
      const optionDetail =
        this.rigid.name +
        "x" +
        this.$formatPriceNumber(this.rigid.amount) +
        " " +
        this.rigid.detail
        // if (!this.isAuthenticated) {
        //   console.log('not login =>', routePath)
        // } else {
        //   console.log('loginnnn')
        // }
        // return
      if (!this.isAuthenticated) {
        const localData = {
          addOnSticker: this.addOnSticker,
          addOnFolding: this.addOnFolding,
          id: this.id,
          amount: this.amount,
          promo_code : this.promo_code,
          reward_id : this.reward_id,
          is_redeem : this.is_redeem,
          zipcode: this.zipcode,
          customizeData: this.customizeData,
          rigid: this.rigid,
          sticker: this.sticker,
          folding: this.folding,
          uuid: this.uuid,
          design: this.design
        };
        this.storeOrder2LocalStorage(localData);
        // this.$root.$emit('bv::show::modal', 'modal-authen')
        localStorage.setItem(
          "click-login-from-page",
          "products-customize"
        );
        localStorage.setItem(
          "click-login-from-page-query",
          JSON.stringify({
            sticker: this.addOnSticker,
            folding: this.addOnFolding,
            id: this.id,
            zipcode: this.zipcode,
            amount: this.amount,
            promo_code : this.promo_code,
            reward_id : this.reward_id,
            is_redeem : this.is_redeem,
            uuid: this.uuid,
            design: this.design
          })
        );
        localStorage.setItem('back_url', routePath)
        this.$router.push(this.localeLocation({ name: "auth-login-via-phone" }));
      } else {
        this.showLoading();
        let sticker = null;
        if (this.sticker) {
          sticker = {
            packaging_type_id: this.sticker.packaging_type_id,
            mapping_input_id: this.sticker.mapping_input_id,
            paper_type_id: this.sticker.selected.paper,
            screen_id: this.sticker.selected.screen,
            amount: parseInt(this.sticker.amount),
            w: parseFloat(this.sticker.w),
            l: parseFloat(this.sticker.l),
            color: true,
            is_brief_design: parseInt(this.sticker.design_check),
            selected_factory: this.sticker.factorySelected.factory_id,
            option_detail: this.stickerOptionDetail(),
            detail: this.stickerOptionDetail(),
          };
        }
        let folding = null;
        if (this.folding) {
          folding = {
            packaging_type_id: this.folding.packaging_type_id,
            mapping_input_id: this.folding.mapping_input_id,
            amount: parseInt(this.folding.amount),
            w: parseFloat(this.folding.w),
            l: parseFloat(this.folding.l),
            h: parseFloat(this.folding.h),
            color: true,
            paper_type_id: this.folding.selected.paper,
            paper_gsm: this.folding.selected.gsm,
            enamel_id: this.folding.selected.enamel,
            special_technic_id: this.folding.selected.special,
            is_brief_design: parseInt(this.sticker.design_check),
            selected_factory: this.folding.factorySelected.factory_id,
            option_detail: this.foldingOptionDetail(),
            detail: this.foldingOptionDetail(),
          };
        }
        const products = [
          {
            packaging_type_id: 4,
            id: parseInt(this.rigid.id),
            amount: parseInt(this.rigid.amount),
            promo_code : this.promo_code,
            discount_type : this.discount_type,
            reward_id : this.reward_id,
            is_redeem : this.is_redeem
          },
        ];

        if (this.addOnSticker === "1") {
          products.push(sticker);
        }
        if (this.addOnFolding === "1") {
          products.push(folding);
        }
        const postData = {
          zipcode: this.rigid.zipcode,
          products,
          uuid: this.uuid,
          design: this.design,
          sum: this.totalPrice(),
          option_detail: optionDetail
        }

        const result = await this.$axios.$post("/rigids", postData);
        this.hideLoading();
        // if (result.error === 'verifyPhone') {
        //   this.$refs.otp.register = false
        //   this.$refs.otp.show = true
        //   this.$refs.otp.email = this.userData.email
        //   this.$refs.otp.showOtpOption = false
        //   // if (result.otpOption && result.otpOption === true) {
        //   //   this.$refs.otp.showOtpOption = true
        //   // }
        //   this.hideLoading()
        //   return false
        // }
        if (result.error === 'verifyEmail') {
          this.$refs.emailModal.show = true
          this.hideLoading()
          return false
        } else if (result.status === 0 || result.status === false) {
          this.hideLoading()
          this.setAlertWithLine({
            show: true,
            message: result.msg,
            header: 'แจ้งเตือน'
          })
          return false
        }
        if (result.status === true) {
          if (this.design === 'design') {
            const parameters = {
              // order_code: result.data.order_code,
              id: result.data.order_id
            }
            this.$router.push(this.localeLocation({
              name: 'brief-design-id',
              params: parameters
            }))
          } else if (goTo === "checkOut") {
            this.$router.push(this.localeLocation({
              name: "checkout-id",
              params: { id: result.data.order_id },
            }));
          } else {
            this.$router.push(this.localeLocation({ name: "my-cart" }));
          }
        }
      }
    },
    sendDataLayer () {
      try {
        this.$axios({
          url: this.$baseurl('orders/' + this.orderId),
          method: 'GET'
        }).then((response) => {
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
            const pkgType = (data.products[0].packaging_type_id) ? data.products[0].packaging_type_id : data.packaging_type_id
            const csData = JSON.parse(data.products[0].option_json)
            const smeData = JSON.parse(data.products[0].sme_json)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ ecommerce: null })
            let dimension2 = ''
            if (data.is_brief_design === 1) {
              dimension2 = 'จ้างออกแบบ'
            } else if (data.design_by_yourself === 1) {
              dimension2 = 'ออกแบบออนไลน์'
            } else {
              dimension2 = 'ออกแบบเอง'
            }
            let dimension4 = ''
            const productData = data.products_all[0].product
            if (productData.h) {
              dimension4 = `${productData.w / 10} x ${productData.l / 10} x ${productData.h / 10}`
            } else {
              dimension4 = `${productData.w / 10} x ${productData.l / 10}`
            }
            const paymentMethod = this.$paymentTypeName(data.payment_type)
            const withVat = data.total * (1 + (7 / 100))
            const vatPrice = withVat - data.total
            let totalWithoutDiscount = data.total_without_discount
            if (data.total_without_discount === null || typeof data.total_without_discount === 'undefined') {
              totalWithoutDiscount = data.total
            }
            const cardPaperActiveName = (smeData && 'cardPaperActiveName' in smeData) ? smeData.cardPaperActiveName : ''
            let gsm = ''
            const zipcode = data.zipcode ? data.zipcode : ''
            if (csData) {
              if ('cs_data' in csData) {
                gsm = (('gsm' in csData.cs_data) ? csData.cs_data.gsm : csData.cs_data[0].paper_gsm + '')
              } else if ('summary' in csData) {
                gsm = (('cs_data' in csData.summary) ? csData.summary.cs_data[0].paper_gsm : '')
              }
            }
            let productCode = ''
            let dimension1 = ''
            let dimension3 = ''
            if (data.products[0].mapping_input_id) {
              productCode = `LOCOPACK_${pkgType}` + data.products[0].mapping_input_id.toString().padStart(9, '0')
              dimension1 = (typeof data.products[0] !== 'undefined' ? 'โรงงาน ' + data.products[0].factory_id : '')
              dimension3 = data.products[0].name
            } else if (data.products[0].rigid_id) {
              productCode = `LOCOPACK_${pkgType}` + data.products[0].rigid_id.toString().padStart(9, '0')
              dimension1 = (typeof data.products[0].rigid.factory_id !== 'undefined' ? 'โรงงาน ' + data.products[0].rigid.factory_id : '')
              dimension3 = data.products[0].rigid.name
            }
            const res = window.dataLayer.push({
              event: 'addToCart',
              paymentMethod,
              discount: totalWithoutDiscount - data.total,
              mold: data.print_plate_price,
              dieCut: data.plate_diecut_price,
              moldCost: data.print_plate_price,
              dieCutCost: data.plate_diecut_price,
              creditCardFee: ((parseInt(data.trans_2c2p_creditcard_id) > 0 || parseInt(data.trans_2c2p_online_id) > 0) ? this.calCostFor2C2P(data.total, data.products[0].amount) : 0),
              designFee: data.sum_design_price,
              shippingCost: data.shipping_price,
              ecommerce: {
                purchase: {
                  actionField: {
                    id: data.order_code,
                    revenue: parseFloat(data.total).toFixed(2),
                    tax: parseFloat(vatPrice).toFixed(2),
                    shipping: 0, // data.shipping_price,
                    coupon: ((smeData && 'promotion_code' in smeData) ? smeData.promotion_code : '')
                  },
                  products: [{
                    id: productCode,
                    name: ((smeData && 'mapping_inputs' in smeData) ? smeData.mapping_inputs.name_th : ''),
                    price: (typeof data.price_per_pcs !== 'undefined' ? data.price_per_pcs : ''),
                    category: this.$pkgTypeThaiName(pkgType),
                    quantity: data.amount,
                    discount: ((csData && csData.discount) ? csData.discount.toFixed(2) : 0),
                    brand: 'Locopack',
                    dimension1,
                    dimension2,
                    dimension3,
                    dimension4,
                    dimension5: cardPaperActiveName,
                    dimension6: gsm,
                    dimension7: zipcode,
                    dimension8: ((smeData && 'color' in smeData) ? this.colorText[pkgType][smeData.color] : ''),
                    dimension9: (data.products[0].plate_text + '').replace('พื้นที่', ''),
                    dimension10: '',
                    dimension11: '',
                    dimension12: data.products[0].delivery_by,
                    dimension13: data.products[0].production_lead_time
                  }]
                }
              }
            })
            if (res) {
              console.log('PrePurchase done')
            } else {
              console.log('PrePurchase failed')
            }
          }
        }).catch(function (error) {
          return { error: true, message: error.message }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    } */
  },
  head() {
    return {
      /* title: this.seo.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.description,
        },
        { hid: "keywords", name: "keywords", content: this.seo.keywords },
      ],
      link: [
        {
          rel: "canonical",
          href: this.seo.canonical_url,
        },
      ], */
    };
  },
};
